import React from "react"
import theme from "theme"
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>O nas | Odkryj Canvas of Colors</title>
        <meta name={"description"} content={"Zanurz się w wyobraźni"} />
        <meta
          property={"og:title"}
          content={"O nas | Odkryj Canvas of Colors"}
        />
        <meta property={"og:description"} content={"Zanurz się w wyobraźni"} />
        <meta
          property={"og:image"}
          content={"https://xovirel.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://xovirel.com/img/4285552.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://xovirel.com/img/4285552.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://xovirel.com/img/4285552.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://xovirel.com/img/4285552.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://xovirel.com/img/4285552.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://xovirel.com/img/4285552.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="0 0 0 0" quarkly-title="Statistics-7">
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            md-width="100%"
            md-margin="0px 0px 50px 0px"
            sm-margin="0px 0px 35px 0px"
          >
            <Image
              src="https://xovirel.com/img/3.jpg"
              display="block"
              width="auto"
              height="100%"
              max-width="100%"
              object-fit="cover"
              object-position="30% 50%"
              md-height="600px"
              sm-height="400px"
            />
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            padding="40px 0px 0px 50px"
            display="flex"
            flex-direction="column"
            justify-content="center"
            lg-padding="0px 0px 0px 50px"
            md-width="100%"
            md-padding="0px 35px 80px 35px"
            sm-padding="0px 0 80px 0"
          >
            <Text
              margin="0px 0px 10px 0px"
              color="--primary"
              font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
            >
              O nas
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              color="--dark"
              font="normal 700 42px/1.2 --fontFamily-sans"
            >
              Odkryj swój potencjał
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              color="#75767d"
            >
              Witamy w Canvas of Colors - przestrzeni, w której kreatywność
              spotyka się z rzemiosłem w sztuce tatuażu. Nasze studio jest
              sanktuarium dla tych, którzy cenią sobie artyzm i oryginalność w
              sztuce skóry. Tutaj każdy tatuaż jest osobistą historią wykonaną z
              precyzją i pasją.
            </Text>
            <Box
              min-width="100px"
              min-height="100px"
              margin="30px 0px 0px 0px"
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              grid-template-rows="repeat(2, 1fr)"
              lg-margin="50px 0px 0px 0px"
              md-margin="40px 0px 0px 0px"
              sm-grid-template-columns="1fr"
              sm-grid-template-rows="auto"
            >
              <Box
                min-width="100px"
                min-height="80px"
                padding="0px 5px 0 5px"
                margin="0px 0px 30px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Nasza historia
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Canvas of Colors narodziło się z chęci połączenia tradycyjnej
                  sztuki tatuażu ze współczesnymi stylami w przyjaznym,
                  profesjonalnym środowisku. Nasze studio przekształciło się w
                  przestrzeń, w której osoby z różnych środowisk mogą wyrazić
                  siebie poprzez unikalne, niestandardowe tatuaże. Jesteśmy
                  zaangażowani w przekształcanie Twoich wizji w pięknie
                  wytatuowaną rzeczywistość.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 5px 0 5px"
                margin="0px 0px 30px 0px"
                min-height="80px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Nasi artyści
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Sercem Canvas of Colors są nasi artyści - każdy z nich został
                  starannie wybrany ze względu na swoje umiejętności,
                  kreatywność i oddanie sztuce tatuażu. To nie tylko tatuatorzy,
                  to rzemieślnicy, którzy rozumieją znaczenie osobistego
                  podejścia do każdego projektu, zapewniając, że Twój tatuaż
                  jest nie tylko dziełem sztuki, ale także częścią Twojej
                  historii życia.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 5px 0 5px"
                margin="0px 0px 30px 0px"
                min-height="80px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Dlaczego my?
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Innowacyjne projekty: Od minimalistycznych symboli po
                  skomplikowane rękawy - nasze portfolio jest świadectwem naszej
                  wszechstronności.
                  <br />
                  <br />
                  Spersonalizowane doświadczenie: Słuchamy, doradzamy i tworzymy
                  - Twoje zadowolenie jest naszą sztuką.
                </Text>
              </Box>
              <Box
                min-width="100px"
                min-height="80px"
                padding="0px 5px 0 5px"
                margin="0px 0px 35px 0px"
              >
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Wysokie standardy: Utrzymujemy najwyższą czystość i używamy
                  najwyższej jakości atramentów i sprzętu.
                  <br />
                  <br />
                  Relaksująca atmosfera: Nasze studio zostało zaprojektowane
                  tak, aby było Twoim osobistym azylem podczas wykonywania
                  tuszu.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
        />
        <Box
          width="50%"
          display="flex"
          flex-direction="column"
          justify-content="space-between"
          md-width="100%"
          padding="0px 0px 0px 0px"
          lg-padding="0px 30px 0px 0px"
          md-padding="0px 0px 0px 0px"
          md-margin="0px 0px 30px 0px"
        >
          <Image
            max-width="340px"
            src="https://xovirel.com/img/4.jpg"
            md-margin="0px 0px 20px 0px"
          />
        </Box>
        <Box
          width="50%"
          display="flex"
          flex-direction="column"
          justify-content="space-between"
          md-width="100%"
        >
          <Text
            margin="0px 0px 40px 0px"
            color="--dark"
            font="--headline2"
            md-margin="0px 0px 30px 0px"
          >
            Stwórz z nami swoją legendę
          </Text>
          <Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
            Wierzymy, że każdy tatuaż ma swoją historię, a każda historia
            zasługuje na idealne płótno. Wybierz Canvas of Colors, gdzie Twoja
            historia jest naszym arcydziełem. Tutaj nie tylko wykonujesz tatuaż,
            ale tworzysz oświadczenie, które przetrwa całe życie.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
